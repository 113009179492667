class RestClient {
  private readonly url: string

  constructor(url: string) {
    this.url = url
  }

  private fetch = async <K>(
    endpoint: string,
    options: RequestInit
  ): Promise<K> => {
    try {
      const response = await fetch(`${this.url}${endpoint}`, options)

      if (!response.ok) {
        throw Error(
          `Network returned status code ${response.status} ${response.statusText}`
        )
      }

      return await response.json()
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return error
      }
    }
  }

  public POST = async <T, K>(
    endpoint: string,
    body: T,
    options?: Omit<RequestInit, 'method' | 'body'>
  ): Promise<K> => {
    const response = await this.fetch<K>(endpoint, {
      ...options,
      method: 'POST',
      body: JSON.stringify(body)
    })

    return response
  }

  public GET = async <T>(
    endpoint: string,
    options?: Omit<RequestInit, 'method'>
  ) => {
    const response = await this.fetch<T>(endpoint, {
      ...options,
      method: 'GET'
    })

    return response
  }

  public PATCH = async <T, K>(
    endpoint: string,
    body: T,
    options?: Omit<RequestInit, 'method' | 'body'>
  ) => {
    const response = await this.fetch<K>(endpoint, {
      ...options,
      method: 'POST',
      body: JSON.stringify(body)
    })

    return response
  }

  public DELETE = async <T, K>(
    endpoint: string,
    body: T,
    options?: Omit<RequestInit, 'method' | 'body' | 'signal'>
  ) => {
    const response = await this.fetch<K>(endpoint, {
      ...options,
      method: 'DELETE',
      body: JSON.stringify(body)
    })

    return response
  }
}

export default RestClient
