'use client'

import React from 'react'
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  Transition,
  TransitionChild,
  DialogTitle
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { LoadingProps } from '../loadingOverlay/loadingOverlay'
import { AnimatePresence, motion } from 'framer-motion'
import { Colors } from '../../types/colors'
import { useColorSchemeContext } from '../../context/colorSchemeContext/colorSchemeContext'

export interface DialogProps extends React.PropsWithChildren {
  title: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  size?: SizeVariant
  loader?: React.ReactElement<LoadingProps>
  progressPercentage?: number
  subtitle?: string
  loadingCompletionColor?: Colors
  onClose?: (byXMark?: boolean) => void
  fitWidthToContent?: boolean
  closeWithXMark?: boolean
}

export enum SizeVariant {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  LARGE_X = 'xl',
  LARGE_2X = '2xl',
  LARGE_3X = '3xl',
  LARGE_4X = '4xl',
  LARGE_5X = '5xl'
}

export default function Dialog({
  title,
  children,
  open,
  setOpen,
  size = SizeVariant.LARGE_2X,
  loader,
  progressPercentage,
  subtitle,
  loadingCompletionColor,
  onClose,
  fitWidthToContent = false,
  closeWithXMark = true
}: DialogProps) {
  const [isLoadingBarAnimationComplete, setIsLoadingBarAnimationComplete] =
    React.useState(false)
  const { colorScheme } = useColorSchemeContext()

  return (
    <Transition appear show={open}>
      <HeadlessDialog
        as="div"
        className={classNames(
          { dark: colorScheme === 'DARK' },
          'fixed inset-0 z-10 overflow-y-auto'
        )}
        onClose={() => {
          setOpen(false)
          if (onClose) onClose()
        }}
      >
        <div className="dark:text-white h-full">
          <div
            className={classNames(
              { hidden: !open },
              'fixed inset-0 bg-black/30 dark:bg-black/40 h-full'
            )}
            aria-hidden="true"
          />
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="h-full"
          >
            <DialogPanel className="px-4 text-center h-full">
              <TransitionChild
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="flex justify-center items-center h-full"
              >
                {/*
                  Internally, Transition.Child caches the className of it's immediate child. This causes problems when
                  dynamically updating the width, as previous width classNames are cached. I can identify where this may
                  be happening in the third-party code (useLatestValue) but cannot find any related issues in their
                  repo. A simple workaround is to render Transition.Child as a div (rather than a fragment) with the
                  bare minimum of classNames that do not change. Since Transition.Child is now a div, it will not modify
                  the immediate child.
                */}
                <div
                  className={classNames(
                    { 'max-w-sm': size === SizeVariant.SMALL },
                    { 'max-w-md': size === SizeVariant.MEDIUM },
                    { 'max-w-lg': size === SizeVariant.LARGE },
                    { 'max-w-xl': size === SizeVariant.LARGE_X },
                    { 'max-w-2xl': size === SizeVariant.LARGE_2X },
                    { 'max-w-3xl': size === SizeVariant.LARGE_3X },
                    { 'max-w-4xl': size === SizeVariant.LARGE_4X },
                    { 'max-w-5xl': size === SizeVariant.LARGE_5X },
                    { 'w-full': !fitWidthToContent },
                    'p-6 my-8 text-left align-middle bg-white dark:bg-darkBlue shadow-2xl dark:shadow-black rounded-2xl relative'
                  )}
                >
                  {loader}
                  <AnimatePresence>
                    {progressPercentage && progressPercentage > 0 && (
                      <div className="w-full mb-4 h-[5px] bg-lightGray dark:bg-darkBlueGray rounded-xl relative z-20 overflow-hidden">
                        <motion.div
                          className="h-full rounded-xl w-full"
                          initial={{ x: '-100%' }}
                          animate={{ x: `${-100 + progressPercentage}%` }}
                          transition={{ ease: 'easeInOut', duration: 0.8 }}
                          style={{
                            backgroundColor:
                              progressPercentage === 100 &&
                              isLoadingBarAnimationComplete
                                ? loadingCompletionColor ||
                                  Colors.successGreenDarkMode
                                : Colors.lightBlue
                          }}
                          onAnimationComplete={() =>
                            setIsLoadingBarAnimationComplete(true)
                          }
                        />
                      </div>
                    )}
                  </AnimatePresence>
                  <div className="flex flex-col gap-5">
                    <div className="grid grid-cols-3 items-center">
                      <DialogTitle
                        as="h3"
                        className={classNames(
                          'relative z-20',
                          { 'col-span-1 font-bold text-lg': subtitle },
                          { 'col-span-2 font-semibold text-lg': !subtitle }
                        )}
                      >
                        {title}
                      </DialogTitle>
                      {subtitle && (
                        <span
                          data-testid="subtitle"
                          className="col-span-1 text-center text-md font-normal text-textLightGray leading-6"
                        >
                          {subtitle}
                        </span>
                      )}
                      <button
                        className="z-20 relative col-span-1 place-self-end"
                        name="close"
                        onClick={() => {
                          if (closeWithXMark) setOpen(false)
                          if (onClose) onClose(true)
                        }}
                      >
                        <span className="visually-hidden">close modal</span>
                        <XMarkIcon className="w-6 h-6 focus:outline-lightBlue" />
                      </button>
                    </div>

                    <div>{children}</div>
                  </div>
                </div>
              </TransitionChild>
            </DialogPanel>
          </TransitionChild>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}
